export default {
  getColor(index) {
    const pallete = ['#028675',
      '#D68903',
      '#9861B3',
      '#0084B0',
      '#877712',
      '#008C4E',
      '#007DC1',
      '#FFA17A',
      '#6671C1',
      '#9D5FAE',
      '#53BCA9',
      '#C4FCEF',
      '#E387FD',
      '#E387FD',
      '#FFE8FF',
      '#876F8D',
      '#344B46',
      '#97B1AA',
      '#447AB2',
      '#7DAEEA',
    ]
    if (index > pallete.length) {
      return pallete[Math.floor(Math.random() * (pallete.length + 1))]
    }

    return pallete[index]
  },
}
