import axios from 'axios'
import environment from '@/environments/environment'
import auth from './auth'

const path = environment.apiUrl

export default {
  getData() {
    return axios.get(`${path}/seggitur`, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
        'Content-Type': 'application/json',
      },
    })
  },
}
