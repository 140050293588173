<template>
  <div>
    <v-row v-if="datos && !loading">
      <v-col
        cols="12"
        md="12"
        class="text-right "
      >
        <v-btn href="https://app.smartsheet.com/dashboards/6jpqMrV9Wm8rFXrPVqVC3X4QW8vvj55gmhRGmw91" target="_blank" class="primary">
          Portal Seggitur
        </v-btn>
      </v-col>
      <v-col
        v-for="(item, index) in datos"
        :key="item.MesaTrabajo"
        cols="12"
        sm="6"
        md="4"
        lg="2"
        :offset-lg="index === 0 ? 1: 0"
      >
        <v-card>
          <v-card-title class="justify-center text-center" style="font-size: medium">
            {{ item.MesaTrabajo }}
          </v-card-title>
          <v-divider></v-divider>
          <v-list>
            <v-list-item
              v-for="anioItem in item.Anios"
              :key="anioItem.Anio"
            >
              <v-list-item-content class="justify-center text-center">
                {{ anioItem.Anio }}
              </v-list-item-content>
              <v-list-item-content class="justify-center text-center">
                {{ anioItem.Valor }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        class="mt-4"
      >
        <bar-chart
          v-if="dataChart"
          :chart-data="dataChart"
        ></bar-chart>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import service from '@/services/seggitur'
import auth from '@/services/auth'
import BarChart from '../analiticaCarga/BarChart'
import chartColor from '@/utils/chartColor'

export default {
  components: {
    BarChart,
  },
  data: () => ({
    loading: true,
    datos: null,
    dataChart: null,
    errorMessage: null,
    error: false,
  }),
  computed: {
    isLoggedIn() {
      return auth.isLoggin()
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push(`/pages/login?return=${this.$route.fullPath}`)
    }
    this.getData()
  },
  methods: {
    getData() {
      this.error = false
      this.loading = true
      service.getData().then(resp => {
        this.datos = resp.data
        this.loading = false
        const anios = {}
        const datasets = []
        const chartLabels = []

        this.datos.forEach(value => {
          chartLabels.push(value.MesaTrabajo)
          value.Anios.forEach((a, i) => {
            if (!anios[a.Anio]) {
              anios[a.Anio] = {
                label: a.Anio,
                backgroundColor: chartColor.getColor(i),
                data: [],
              }
            }
            anios[a.Anio].data.push(Number(a.Valor.replace('%', '')))
          })
        })
        Object.keys(anios).forEach(k => {
          datasets.push(anios[k])
        })

        this.dataChart = {
          labels: chartLabels,
          datasets,
        }
      }).catch(e => {
        this.error = true
        this.errorMessage = e.data
      })
    },
  },
}
</script>
